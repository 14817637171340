import * as React from 'react';
import '../styles/grid.css';
import '../styles/styles.css';
import Layout from '../components/Layout';

export default function Imprint (): React.ReactElement {

  return (
    <Layout>
      <div className="container wrapperContainer">
        <div>
          <div className="col-12">
            <h3>Impressum</h3>
            <h4>Für diese Website verantwortlicher Herausgeber:</h4>
            <p>
              Triceonic GmbH<br />
              Gesellschaft mit beschränkter Haftung<br />
              c/o startup300 AG<br />
              Peter-Behrens-Platz 10<br />
              4020 Linz / Austria<br />
            </p>
            <p>
              UID-Nr. ATU77229857 <br />
              Firmenbuchnummer FN 563220 s <br />
              Firmenbuchgericht: Landesgericht Linz<br />
            </p>
            <p>
              Kontaktdaten: <br />
              E-Mail: <a href="mailto:office@triceonic.com">office(at)triceonic.com</a><br />
            </p>
            <p>
              Unternehmensgegenstand: Erstellung von Software und Software-Applikationen
            </p>
            <p>
              Mitglied bei: WKO<br />
              Berufsrecht der Gewerbeordnung: <a href="https://www.ris.bka.gv.at/" target="_blank" >https://www.ris.bka.gv.at/</a>
            </p>
            <p>
              <h4>Nutzungsbedingungen:</h4>
              Sämtliche Texte auf der Website wurden sorgfältig geprüft. Dessen ungeachtet kann keine Garantie für Richtigkeit,
              Vollständigkeit und Aktualität der Angaben übernommen werden.<br />
              Die Triceonic GmbH übernimmt deshalb hinsichtlich der Richtigkeit, Vollständigkeit und Aktualität der Webseiten
              keine Haftung für Schäden materieller oder ideeller Art, die durch die Nutzung fehlerhafter oder unvollständiger
              Informationen innerhalb dieses Internetangebotes entstehen.<br />
              Texte, Grafiken und Bilder sind urheberrechtlich geschützt; eine Verwendung ist nur mit ausdrücklicher
              Genehmigung des Inhabers gestattet.
            </p>
            <p>
              <h4>Haftungsausschluss:</h4>
              Wir weisen darauf hin, dass wir trotz sorgfältiger Bearbeitung keine Haftung für Inhalt und Aktualität
              der Website übernehmen können. Links zu anderen Websites wurden sorgfältig ausgewählt. Auf deren Inhalt
              hat Triceonic GmbH aber keinen Einfluss und kann daher keine diesbezügliche Verantwortung übernehmen.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
